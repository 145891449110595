import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import styled from '@emotion/styled'
import Layout from '../Templates/Layout'
import PostListItem from '../Organisms/PostListItem'

const Wrapper = styled.div`
  padding: 2rem 2.5rem;
`

const Home = ({ data }) => {
  const {
    allMarkdownRemark: { edges }
  } = data
  return (
    <Layout>
      <Helmet title={'Not Be Forgotten'} />
      <Wrapper>
        {
          edges.map(({ node }) => (
            <PostListItem
              key={node.id}
              title={node.frontmatter.title}
              created_at={node.frontmatter.created_at}
              updated_at={node.frontmatter.updated_at}
              tags={node.frontmatter.tags}
              excerpt={node.excerpt}
              path={node.frontmatter.path}
              cover={node.frontmatter.cover ? node.frontmatter.cover.childImageSharp.fluid : {}}
            />
          ))
        }
      </Wrapper>
    </Layout>
  )
}

Home.propTypes = {
  data: PropTypes.object
}

export default Home

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { draft: { ne: true } } },
      sort: { order: DESC, fields: [frontmatter___created_at] }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 75)
          frontmatter {
            title
            path
            tags
            created_at(formatString: "YYYY年MM月DD日")
            updated_at(formatString: "YYYY年MM月DD日")
            cover {
              childImageSharp {
                fluid(
                  maxWidth: 1000
                  quality: 90
                  traceSVG: { color: "#2B2B2F" }
                ) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`