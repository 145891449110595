import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import TagsBlock from './TagsBlock'
import Container from '../Templates/Container'

const Wrapper = styled.article`
  display: flex;
  @media (max-width: 1000px) {
    display: block;
  }
  margin: 10px;
`
/* transition: ${props => props.theme.transitions.boom.transition}; */

const Image = styled.div`
  margin: auto;
  position: relative;
  box-shadow: ${props => props.theme.shadows.post.default};
  border-radius: 8px;
  min-height: 300px;
  img {
    border-radius: 8px;
  }
  &:hover {
    box-shadow: ${props => props.theme.shadows.post.hover};
    transform: scale(1.04);
  }
  a {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 8px;
    > div {
      position: static !important;
    }
    > div > div {
      position: static !important;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 0 5px ${props => props.theme.colors.background};
    }
  }
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  @media (max-width: 800px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
    margin-bottom: 1.5rem;
  }
  @media (max-width: 500px) {
    min-height: 200px;
  }
`

/* transition: all ${props => props.theme.transitions.default.duration}; */
const Information = styled.div`
  padding: 1rem 1.5rem;
  h1 {
    font-size: 2rem;
    display: inline-block;
    color: ${props => props.theme.colors.text.base};
    &:hover {
      color: ${props => props.theme.colors.text.light};
    }
  }
  text-align: left;
  flex-basis: 100%;
  max-width: 100%;
  width: 100%;
  @media (max-width: 800px) {
    flex-basis: 100%;
    max-width: 100%;
    width: 100%;
  }
`

const Date = styled.div`
  margin: 5px;
  width: 100%;
  text-align: right;
  color: ${props => props.theme.colors.text.light};
`

const Title = styled.h1`
  margin: 0;
`

const PostListItem = ({ path, cover, title, created_at, updated_at, excerpt, tags }) => (
  <Container>
    <Wrapper>
      <Image>
        <Link to={path} title={title}>
          {
            cover !== "" && <Img fluid={cover} alt={title} />
          }
        </Link>
      </Image>
      <Information>
        <Link to={path}>
          <Title>{title}</Title>
        </Link>
        <Date>作成日:{created_at}</Date>
        <Date>更新日:{updated_at}</Date>
        <TagsBlock list={tags} />
        {excerpt}
      </Information>
    </Wrapper>
  </Container>
)

PostListItem.propTypes = {
  cover: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  excerpt: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  title: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired
}
export default PostListItem